import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('label', {
    staticClass: "tw-min-h-6 tw-w-fit tw-text-subtitle-2 !tw-flex tw-gap-1 tw-items-center tw-rounded-md hover:tw-bg-mix-amount-10 active:tw-bg-mix-amount-30",
    class: {
      'tw-bg-mix-primary': _setup.checked,
      'tw-bg-mix-secondary': !_setup.checked,
      'tw-cursor-pointer': !_vm.disabled,
      'tw-pointer-events-none tw-text-disabled': _vm.disabled
    }
  }, [_c('input', {
    staticClass: "tw-hidden",
    attrs: {
      "type": "radio",
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _setup.value,
      "checked": _setup.checked
    },
    on: {
      "input": function input($event) {
        return _setup.emits('update:current', _setup.value);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-relative"
  }, [_c('mdi-radiobox-blank', {
    staticClass: "tw-size-6 tw-text-secondary",
    class: {
      '!tw-text-disabled': _vm.disabled
    }
  }), _vm._v(" "), _c('mdi-radiobox-marked', {
    staticClass: "tw-size-6 tw-text-primary tw-absolute tw-inset-0 tw-transition-opacity tw-opacity-0",
    class: {
      '!tw-text-disabled': _vm.disabled,
      'tw-opacity-100': _setup.checked
    }
  })], 1), _vm._v(" "), _vm.$slots.default ? [_vm._t("default")] : [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };